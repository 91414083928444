import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";

function MicrosoftCallback() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  
  useEffect(() => {
    console.log("Microsoft callback mounted");
    console.log("Current URL:", window.location.href);
    
    // Get token from URL hash (fragment)
    const token = window.location.hash.substring(1);
    
    if (token) {
      console.log("Got token from URL hash");
      
      // Store token in localStorage
      localStorage.setItem("token", token);
      
      // Update auth context
      authContext.setIsAuthenticated(true);
      
      // Redirect to dashboard
      navigate("/dashboards/analytics");
    } else {
      console.error("No token in URL hash");
      setTimeout(() => navigate("/auth/login"), 3000);
    }
  }, [navigate, authContext]);
  
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      {error ? (
        <MDTypography variant="h5" color="error" textAlign="center">
          {error}
          <br />
          <MDTypography variant="body2" color="text">
            Redirecting to login page...
          </MDTypography>
        </MDTypography>
      ) : (
        <>
          <CircularProgress color="info" />
          <MDTypography variant="h5" mt={2}>
            Completing your Microsoft login...
          </MDTypography>
        </>
      )}
    </MDBox>
  );
}

export default MicrosoftCallback;